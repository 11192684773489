/*
  To import in local styles put this line:
  @import '~src/assets/styles/vars/_colors';
*/
$red: #DC005C;
$blue: #00BFFF;
$yellow: #FFD400;
$purple: #624DCB;
$dark-purple: #2D2E82;
$black: #1D1D3C;
$white: #FFFFFF;
$light-blue: #EBF3F7;
$light-blue-transparent: #ebf3f7a1;