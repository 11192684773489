/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~src/assets/styles/vars/_colors';

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;

  font-family: 'Montserrat', sans-serif;
  & * {
    box-sizing: border-box;
  }
}
a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;

  .row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    max-width: 1170px;
    width: 100%;
  }

  @media (max-width: 1600px) and (min-width: 1170px) {
    .row {
      max-width: 1050px;
    }
  }

  @media (max-width: 1169px) and (min-width: 1050px) {
    .row {
      max-width: 920px;
    }
  }

  @media (max-width: 1049px) and (min-width: 920px) {
    .row {
      max-width: 820px;
    }
  }

  @media (max-width: 919px) {
    .row {
      width: 100%;
      padding: 0 50px;
    }
  }

  @media (max-width: 420px) {
    .row {
      padding: 0 20px;
    }
  }
}

.link {
  display: block;
  line-height: 40px;
  text-transform: uppercase;
  background-color: $red;
  padding: 0 40px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 3px 5px rgba(0, 0, 0, 0.35);
  color: $white;
  text-decoration: none;

  &:hover {
    box-shadow: none;
  }
}

@media (max-width: 420px) {
  .row {
    padding: 0 20px;
  }

  .link {
    font-size: 12px;
  }
}